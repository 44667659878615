import Vue from 'vue'
import { InputGroupPlugin, ToastPlugin, ModalPlugin, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// import * as VueGoogleMaps from "vue2-google-maps"

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import { auth } from './firebase'
import Vuelidate from 'vuelidate'
// import VueFileAgent from 'vue-file-agent';
// import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
// import { SlickList, SlickItem } from 'vue-slicksort';
import VueMeta from 'vue-meta'
import VueFormWizard from 'vue-form-wizard'
// import VueEasyLightbox from 'vue-easy-lightbox'
// import PrettyCheckbox from 'pretty-checkbox-vue'
import 'pretty-checkbox/src/pretty-checkbox.scss'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { library } from '@fortawesome/fontawesome-svg-core'
// import { faUserSecret, faBed, faBath, faTableCells, faCar, faChartArea, } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JsonCSV from 'vue-json-csv'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'
Vue.use(VueAxios, axios)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(InputGroupPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(IconsPlugin)

// Plugin Vuelidate
Vue.use(Vuelidate)

// Composition API
Vue.use(VueCompositionAPI)

// Lightbox
// Vue.use(VueEasyLightbox)

// Google Maps
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: "AIzaSyDGFY1cOEptM3Jdq9j1MS3o2kCjuM0lgEw",
//   },
// });

// Vue Form Wizard
Vue.use(VueFormWizard)

// File Agent (images)
// Vue.use(VueFileAgent);
// Vue.component('vfa-sortable-list', SlickList);
// Vue.component('vfa-sortable-item', SlickItem);

// Meta Tags
Vue.use(VueMeta, {
  keyName: 'head'
})

// Pretty Checkbox
// Vue.use(PrettyCheckbox);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
require('@core/assets/fonts/din/iconfonts.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

/* add icons to the library */
// library.add(faUserSecret, faBed, faBath, faTableCells, faCar, faChartArea,)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('downloadCsv', JsonCSV)

Vue.config.productionTip = false

auth.onAuthStateChanged((user) => {
  if(user) {
    store.dispatch('setUsuario', user)
  }
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
});