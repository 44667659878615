import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";
import "firebase/functions";

  const firebaseConfig = {
    apiKey: "AIzaSyA_ThX2meiStpGcJgej888dhdz1OOx654E",
    authDomain: "nycpg-67e48.firebaseapp.com",
    databaseURL: "https://nycpg-67e48.firebaseapp.com",
    projectId: "nycpg-67e48",
    storageBucket: "nycpg-67e48.appspot.com",
    messagingSenderId: "40647711281",
    appId: "1:40647711281:web:b237479ac1436b5a7a5310"
  };

  firebase.initializeApp(firebaseConfig);

  var secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");

  const db = firebase.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();
  const realtime = firebase.database();
  const functions = firebase.functions();

  export {firebase,db,auth,storage,realtime,functions,secondaryApp}