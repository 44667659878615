import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import VueMeta from 'vue-meta'
import { auth, db } from '@/firebase'

Vue.use(VueRouter)
Vue.use(VueMeta)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: '/', redirect: { name: 'auth-login' } },
    { path: '/', redirect: { name: 'access-control' } },
    // { path: '/access-control', redirect: { name: 'access-control' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const user = auth.currentUser
  
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!user) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && user) {
    db.collection('usuarios').doc(user.uid).get()
    .then(userDoc => {
      // var canAccess = true
      // if(to.meta.access) {
      //   canAccess = false
      //   if(to.meta.access === 'manage' && userDoc.data().role === 'admin') {
      //     canAccess = true
      //   }
      // }
      // console.log(canAccess)
      
      // if(canAccess) {
        next(getHomeRouteForLoggedInUser(userDoc.data().role))
      // } else {
      //   next({ name: 'misc-not-authorized' })
      // }
    })
  }

  if(user) {
    db.collection('usuarios').doc(user.uid).get()
    .then(userDoc => {
      var canAccess = true
      if(to.meta.access) {
        canAccess = false
        if(to.meta.access.includes('manage') && userDoc.data().role === 'admin') {
          canAccess = true
        } else if(to.meta.access.includes('manageAndStaff') && userDoc.data().role === 'staff') {
          // if staff member has permissions of clinician viewer
          if(to.meta.access.includes('manageAndStaffViewer')) {
            if(userDoc.data().permissions.includes('clinician viewer')) {
              canAccess = true
            }
          }

          // if staff member has permissions of data specialist
          if(to.meta.access.includes('manageAndStaffDataS')) {
            if(userDoc.data().permissions.includes('data specialist')) {
              canAccess = true
            }
          }
          
          // if staff member has permissions of manage users
          if(to.meta.access.includes('manageAndStaffManage')) {
            if(userDoc.data().permissions.includes('manage users')) {
              canAccess = true
            }
          }
        }
      }
      // console.log(to.meta.access,canAccess)
      
      if(canAccess) {
        return next()
      } else {
        return next({ name: 'misc-not-authorized' })
      }
    })
    // return next()
  } else {
    return next()
  }
})

// if(userDoc.data().role === 'admin') {

// } else if(userDoc.data().role === 'staff') {
//     if(userDoc.data().permissions.includes('manage users')) {
//     } else if(userDoc.data().permissions.includes('data specialist')) {
//     } else if(userDoc.data().permissions.includes('clinician viewer')) {

// } else if(userDoc.data().role === 'clinician') {

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
const DEFAULT_TITLE = 'NYCPG';
router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  Vue.nextTick(() => {
    // console.log(to.meta)
    document.title = to.meta.title || DEFAULT_TITLE;
    // document.meta.title = to.meta.title || DEFAULT_TITLE;
  });
})

export default router
