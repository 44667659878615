export default [
  // *===============================================---*
  // *--------- Usuarios -------------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/users/list',
  //   name: 'usuarios',
  //   component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  //   meta: {
  //     resource: 'ACL',
  //     action: 'read',
  //     pageTitle: 'Users',
  //     title: 'Users',
  //     contentClass: 'ecommerce-application',
  //   },
  // },
  {
    path: '/apps/users/add/clinician',
    name: 'add-clinician',
    component: () => import('@/views/apps/user/users-add/AddClinician.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Create clinician',
      title: 'Create new clinician',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/users/edit/clinician/:id',
    name: 'edit-clinician',
    component: () => import('@/views/apps/user/users-edit/EditClinician.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Edit clinician',
      title: 'Edit clinician',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/clients',
    name: 'clients',
    component: () => import('@/views/apps/user/users-list/ClientsList.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      // access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Clients',
      title: 'Clients',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/disabled-clients',
    name: 'disabled-clients',
    component: () => import('@/views/apps/user/users-list/DisabledClientsList.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage'],
      pageTitle: 'Disabled Clients',
      title: 'Disabled Clients',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/add/client',
    name: 'add-client',
    component: () => import('@/views/apps/user/users-add/AddClient.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Add Client',
      title: 'Add Client',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/edit/client/:id',
    name: 'edit-client',
    component: () => import('@/views/apps/user/users-edit/EditClient.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      // access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Edit Client',
      title: 'Edit Client',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/clinicians',
    name: 'all-clinicians',
    component: () => import('@/views/apps/user/users-list/ClinicianList.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffViewer','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Clinicians',
      title: 'Clinicians',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/disabled-clinicians',
    name: 'disabled-clinicians',
    component: () => import('@/views/apps/user/users-list/DisabledClinicianList.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaffViewer','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Disabled Clinicians',
      title: 'Disabled Clinicians',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/staff-members',
    name: 'all-staff-members',
    component: () => import('@/views/apps/user/users-list/StaffList.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Staff Members',
      title: 'Staff Members',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/disabled-staff-members',
    name: 'disabled-staff-members',
    component: () => import('@/views/apps/user/users-list/DisabledStaffList.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Disabled Staff Members',
      title: 'Disabled Staff Members',
      contentClass: 'ecommerce-application',
    },
  },
  // *===============================================---*
  // *--------- Claims -------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/claims',
    name: 'claims',
    component: () => import('@/views/apps/claims/ClaimsList.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'All Claims',
      title: 'All Claims',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/claims/approved-claims',
    name: 'approved-claims',
    component: () => import('@/views/apps/claims/ApprovedClaimsList.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Approved Claims',
      title: 'Approved Claims',
      contentClass: 'ecommerce-application',
    },
  },
  // {
  //   path: '/apps/disabled-claims',
  //   name: 'disabled-claims',
  //   component: () => import('@/views/apps/claims/DisabledClaimsList.vue'),
  //   meta: {
  //     resource: 'ACL',
  //     action: 'read',
  //     access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
  //     pageTitle: 'Disabled Claims',
  //     title: 'Disabled Claims',
  //     contentClass: 'ecommerce-application',
  //   },
  // },
  // *===============================================---*
  // *--------- Client Activity -------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/client-activities',
    name: 'client-activities',
    component: () => import('@/views/apps/activity/ClientActivityList.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'All Client Activity',
      title: 'All Client Activity',
      contentClass: 'ecommerce-application',
    },
  },
  // *===============================================---*
  // *--------- Reports -------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/reports/general',
    name: 'reports-general',
    component: () => import('@/views/apps/reports/ReportsGeneral.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Reports General',
      title: 'Reports General',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/reports/call-data',
    name: 'reports-call-reports',
    component: () => import('@/views/apps/reports/ReportsCallData.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Reports Call Data',
      title: 'Reports Call Data',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/reports/active-treatment',
    name: 'reports-active-treatment',
    component: () => import('@/views/apps/reports/ReportsActiveTreatment.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Reports Active Treatment',
      title: 'Reports Active Treatment',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/reports/discharge-data',
    name: 'reports-discharge-data',
    component: () => import('@/views/apps/reports/ReportsDischargeData.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Reports Discharge Data',
      title: 'Reports Discharge Data',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/reports/insurance-utilization',
    name: 'reports-insurance-utilization',
    component: () => import('@/views/apps/reports/ReportsInsuranceUtilization.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Reports Payment/Insurance Utilization',
      title: 'Reports Payment/Insurance Utilization',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/reports/finance-information',
    name: 'reports-finance-information',
    component: () => import('@/views/apps/reports/ReportsFinanceInformation.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: ' ReportsFinance Information',
      title: 'Reports Finance Information',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/reports/clinician-report',
    name: 'reports-clinician',
    component: () => import('@/views/apps/reports/ReportsClinicianReport.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      // access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Clinician Report',
      title: 'Clinician Report',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/apps/notifications/Notifications.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Notifications',
      title: 'Notifications',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/notification-details/:id',
    name: 'notification-details',
    component: () => import('@/views/apps/notifications/NotificationDetails.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Notification Details',
      title: 'Notification Details',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/notifications-clinician',
    name: 'notifications-clinician',
    component: () => import('@/views/apps/notifications/NotificationsClinician.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      // access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Notifications',
      title: 'Notifications',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/notification-clinician-details/:id/:date',
    name: 'notification-clinician-details',
    component: () => import('@/views/apps/notifications/NotificationClinicianDetails.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      // access: ['manage','manageAndStaff','manageAndStaffDataS','manageAndStaffManage'],
      pageTitle: 'Notification Details',
      title: 'Notification Details',
      contentClass: 'ecommerce-application',
    },
  },
]
